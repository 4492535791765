body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
     }
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: rgb(0,23,45);
}

.App {
  text-align: center;
}

.section h1 {
  font-size: 3rem;
  color: white
}

.active {
  color: #61dafb;
}

main {
  padding-top: 58px; /* Adjust the value according to your header's height */
}

#home {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #00172d, #090979); /* Gradient from #00172d to #090979 */
}

#skills {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #090979;
}

#portfolio {
  height: 125vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #090979; /* Gradient from #090979 to #00d4ff */
}

#contact {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #090979, #00172d); /* Gradient from #090979 to #00d4ff */
}

.typewriter-container {
  display: inline-block;
  overflow: hidden;
  border-right: 0.15em solid #fff; /* Cursor style, adjust thickness and color as needed */
  white-space: nowrap; /* Keep the text on one line */
  margin: 0 auto; /* Center the text */
  letter-spacing: 0.15em; /* Adjust spacing between letters */
}

@media only screen and (max-width: 600px) {
  .section
  {
  height: auto !important;
  min-height: calc(100vh - 50px) !important;
  padding-left: 20px;
  padding-right: 20px;
  }
  
  .section h1 {
  font-size: 2rem;
  color: #fff;
  }
  
  .contactHeader
  {
  max-width: 50%;
  }
  
  #contact .btn
  {
  font-size: 18px !important;
  }
}
body {
  margin: 0;
  padding: 0;
}

.App-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0; /* Set both left and right sides to 0 to center the navbar */
    width: 100%;
    background: transparent;
    padding-top: 20px;
    color: white;
    z-index: 100;
    display: flex; /* Use flexbox to center the navigation links */
    justify-content: center;
  }
  
  .App-header nav {
    display: flex;
    justify-content: space-around;
  }
  
  /* Reduce the spacing between each navigation link */
  .App-header nav a {
    margin: 0 20px; /* Adjust the margin around each link to decrease spacing */
    color: white;
    text-decoration: none;
    position: relative;
    cursor: pointer;
  }
  
  /* Add the smooth underline effect */
  .App-header nav a:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: #61dafb; /* Set the underline color */
    transition: width 0.3s ease; /* Set the transition duration for smooth effect */
  }
  
  /* Update the underline width when the link is active */
  .App-header nav a.active:after {
    width: 100%;
  }
  
/* Replace '.portfolio-image' with the appropriate class name for your images */
.portfolio-image {
    width: 300px; /* Set the width to 500 pixels */
    height: 300px; /* Set the height to 500 pixels */
    /* Optionally, you can use 'object-fit' to control how the image fits within the container */
    object-fit: cover; /* Adjust this property to control the aspect ratio */
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
  }
  
  .portfolio-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px; /* Add a 20px gap between the cards */
    max-width: 1245px; /* Set a fixed width for the container to ensure cards wrap properly */
    margin: 0 auto; /* Center the container on the page */
  }
  
  .portfolio-card {
    background-color: white;
    border-radius: 0.375rem;
    --cui-card-spacer-y: 1rem;
    --cui-card-spacer-x: 1rem;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    flex-basis: calc(25% - 20px); /* Four cards per row, each taking 25% width minus the gap */
    min-width: 300px; /* Set the minimum width for each card to 300px */
    max-width: 300px; /* Set the maximum width for each card to 300px */
  }
  
  .portfolio-card-title {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .portfolio-card-text {
    margin-top: 15px;
    padding-bottom: 15px;
  }
  

/* Replace '.skill-image' with the appropriate class name for your images */
.skill-image {
    width: 300px; /* Set the width to 500 pixels */
    height: 300px; /* Set the height to 500 pixels */
    /* Optionally, you can use 'object-fit' to control how the image fits within the container */
    object-fit: cover; /* Adjust this property to control the aspect ratio */
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
  }
  
  .skill-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px; /* Add a 20px gap between the cards */
    max-width: 1245px; /* Set a fixed width for the container to ensure cards wrap properly */
    margin: 0 auto; /* Center the container on the page */
  }
  
  .skill-card {
    background-color: white;
    border-radius: 0.375rem;
    --cui-card-spacer-y: 1rem;
    --cui-card-spacer-x: 1rem;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    flex-basis: calc(25% - 20px); /* Four cards per row, each taking 25% width minus the gap */
    min-width: 300px; /* Set the minimum width for each card to 300px */
    max-width: 300px; /* Set the maximum width for each card to 300px */
  }
  
  .skill-card-title {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .skill-card-text {
    margin-top: 15px;
    padding-bottom: 15px;
    font-weight: bold;
  }

#contactContainer {
  width: 30%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

@media screen and (max-width: 768px) {
  #contactContainer {
    width: 50%;
  }

  button {
   font-size: 1.5rem;
  }
} 

.contactHeader {
  margin-right: 15px;
  font-size: 2.5rem;
}

.contactFormLabel {
  color: white;
  margin-top: 25px;
}

.contactFormButton {
  margin-top: 25px;
}

@media screen and (max-width: 600px) {
  #contactContainer {
    width: 80%;
  }
} 
footer {
    background-color: transparent;
    color: white;
    text-align: center;
    padding: 10px;
}
