#contactContainer {
  width: 30%;
  max-width: fit-content;
}

@media screen and (max-width: 768px) {
  #contactContainer {
    width: 50%;
  }

  button {
   font-size: 1.5rem;
  }
} 

.contactHeader {
  margin-right: 15px;
  font-size: 2.5rem;
}

.contactFormLabel {
  color: white;
  margin-top: 25px;
}

.contactFormButton {
  margin-top: 25px;
}

@media screen and (max-width: 600px) {
  #contactContainer {
    width: 80%;
  }
} 