@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
     }
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: rgb(0,23,45);
}

.App {
  text-align: center;
}

.section h1 {
  font-size: 3rem;
  color: white
}

.active {
  color: #61dafb;
}

main {
  padding-top: 58px; /* Adjust the value according to your header's height */
}

#home {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #00172d, #090979); /* Gradient from #00172d to #090979 */
}

#skills {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #090979;
}

#portfolio {
  height: 125vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #090979; /* Gradient from #090979 to #00d4ff */
}

#contact {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #090979, #00172d); /* Gradient from #090979 to #00d4ff */
}

.typewriter-container {
  display: inline-block;
  overflow: hidden;
  border-right: 0.15em solid #fff; /* Cursor style, adjust thickness and color as needed */
  white-space: nowrap; /* Keep the text on one line */
  margin: 0 auto; /* Center the text */
  letter-spacing: 0.15em; /* Adjust spacing between letters */
}

@media only screen and (max-width: 600px) {
  .section
  {
  height: auto !important;
  min-height: calc(100vh - 50px) !important;
  padding-left: 20px;
  padding-right: 20px;
  }
  
  .section h1 {
  font-size: 2rem;
  color: #fff;
  }
  
  .contactHeader
  {
  max-width: 50%;
  }
  
  #contact .btn
  {
  font-size: 18px !important;
  }
}