body {
  margin: 0;
  padding: 0;
}

.App-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0; /* Set both left and right sides to 0 to center the navbar */
    width: 100%;
    background: transparent;
    padding-top: 20px;
    color: white;
    z-index: 100;
    display: flex; /* Use flexbox to center the navigation links */
    justify-content: center;
  }
  
  .App-header nav {
    display: flex;
    justify-content: space-around;
  }
  
  /* Reduce the spacing between each navigation link */
  .App-header nav a {
    margin: 0 20px; /* Adjust the margin around each link to decrease spacing */
    color: white;
    text-decoration: none;
    position: relative;
    cursor: pointer;
  }
  
  /* Add the smooth underline effect */
  .App-header nav a:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: #61dafb; /* Set the underline color */
    transition: width 0.3s ease; /* Set the transition duration for smooth effect */
  }
  
  /* Update the underline width when the link is active */
  .App-header nav a.active:after {
    width: 100%;
  }
  