/* Replace '.portfolio-image' with the appropriate class name for your images */
.portfolio-image {
    width: 300px; /* Set the width to 500 pixels */
    height: 300px; /* Set the height to 500 pixels */
    /* Optionally, you can use 'object-fit' to control how the image fits within the container */
    object-fit: cover; /* Adjust this property to control the aspect ratio */
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
  }
  
  .portfolio-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px; /* Add a 20px gap between the cards */
    max-width: 1245px; /* Set a fixed width for the container to ensure cards wrap properly */
    margin: 0 auto; /* Center the container on the page */
  }
  
  .portfolio-card {
    background-color: white;
    border-radius: 0.375rem;
    --cui-card-spacer-y: 1rem;
    --cui-card-spacer-x: 1rem;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    flex-basis: calc(25% - 20px); /* Four cards per row, each taking 25% width minus the gap */
    min-width: 300px; /* Set the minimum width for each card to 300px */
    max-width: 300px; /* Set the maximum width for each card to 300px */
  }
  
  .portfolio-card-title {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .portfolio-card-text {
    margin-top: 15px;
    padding-bottom: 15px;
  }
  
